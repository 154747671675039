import { useEffect, useState } from "react";
import logo from "../../../images/logo.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import './finalBill.css';
import { useSelector } from "react-redux";

export default function FinalBill() {

    let location = useLocation();
    let billDetails = location.state.billDetails;

    const [billData, setBillData] = useState(billDetails);
    let [billItems, setBillItems] = useState(billDetails.billItems);
    const [discounts, setDiscounts] = useState({});
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [rebate, setRebate] = useState(0);
    const [amountAfterRebate, setAmountAfterRebate] = useState(0);
    const [cgst, setCGST] = useState(0);
    const [amountOfCGST, setAmountOfCGST] = useState(0);
    const [sgst, setSGST] = useState(0);
    const [amountOfSGST, setAmountOfSGST] = useState(0);
    const [igst, setIGST] = useState(0);
    const [amountOfIGST, setAmountOfIGST] = useState(0);
    const [finalAmount, setfinalAmount] = useState(0);
    const [roundOff, setRoundOff] = useState(0);

    const handleDiscountChange = (index, value) => {
        const updatedDiscounts = { ...discounts, [index]: value };
        setDiscounts(updatedDiscounts);

        //update the amount after applying discount
        const updatedBillItems = billItems.map((item, i) => {
            if (i === index) {
                const totalWithoutDiscount = item.price * item.qty;
                const discountAmount = (totalWithoutDiscount * value) / 100;
                
                const amount = totalWithoutDiscount - discountAmount;

                return {
                    ...item,
                    amt:amount,
                    discountRate:value,
                };
            }
            return item;
        });
        setBillItems(updatedBillItems);
        calculateTotal(updatedBillItems);
    };


    const calculateTotal = (items) => {
        let totalQty = 0;
        let totalAmt = 0;

        items.forEach(item => {
            totalQty += item.qty;
            totalAmt += item.amt;
        });

        setTotalQuantity(totalQty);
        setTotalAmount(totalAmt);
        setAmountAfterRebate(totalAmt - rebate);
    };

    const handleRebateChange = (value) => {
        const rebateValue = (value) || " ";
        setRebate(rebateValue);
        const updatedAmountAfterRebate = totalAmount - rebateValue;
        setAmountAfterRebate(updatedAmountAfterRebate);

    };

    const handleCGSTChange = (value) => {
        const cgstValue = (value) || " ";
        setCGST(cgstValue);

        const cgstAmount = (amountAfterRebate * cgstValue) / 100;
        setAmountOfCGST(cgstAmount);
        handleFinalValue(cgstAmount, amountOfSGST, amountOfIGST);

    };

    const handleSGSTChange = (value) => {
        const sgstValue = (value) || " ";
        setSGST(sgstValue);

        const sgstAmount = (amountAfterRebate * sgstValue) / 100;
        setAmountOfSGST(sgstAmount);
        handleFinalValue(amountOfCGST, sgstAmount, amountOfIGST);
    };

    const handleIGSTChange = (value) => {
        const igstValue = (value) || " ";
        setIGST(igstValue);

        const igstAmount = (amountAfterRebate * igstValue) / 100;
        setAmountOfIGST(igstAmount);
        handleFinalValue(amountOfCGST, amountOfSGST, igstAmount);

    };

    const handleFinalValue = (cgstAmount, sgstAmount, igstAmount) => {
        const final = cgstAmount + sgstAmount + igstAmount;
        const totalAfterGST = amountAfterRebate + final;

        const roundOffValue = Math.abs(Math.round(totalAfterGST) - totalAfterGST);
        setRoundOff(roundOffValue);

        setfinalAmount(Math.round(totalAfterGST));
    };

    const handleKeyPress = (e) => {
        const { name, value } = e.target;
        if (!/[0-9.]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePaste = (e) => {
        const { name, value } = e.target;
        const paste = (e.clipboardData || window.clipboardData).getData("text");
        if (!/^\d+$/.test(paste)) {
            e.preventDefault();
        }
    };





    const handleSubmit = async (e) => {
        e.preventDefault();


        const updatedBillItems = billItems.map((item,index)=>({
            ...item,
            discountRate: discounts[index] || 0,
            amt: item.amt || item.price * item.qty - (item.price * item.qty * (discounts[index] || 0)) / 100,
        }));


        let dataToSend = {
                     ...billData,
                    billItems: updatedBillItems,
                    amount: totalAmount,
                    discount: parseInt(rebate),
                    totalAmt: amountAfterRebate,
                    totalCGST: amountOfCGST,
                    totalSGST: amountOfSGST,
                    totalIGST: amountOfIGST,
                    roundOff: roundOff,
                    netAmount: finalAmount,
                    dStatus: "F"

        };
        console.log(dataToSend);
        

            // await axios.post(
            //     process.env.REACT_APP_BASE_URL + "/Bills/SaveFinalBill",
            //         dataToSend,
            //         {
            //             headers: {
            //                 Authorization: `Bearer ${window.token}`},
            //           }
            // )
            // .then((reply) => {
            //     if (reply.status == 200) {
            //         if (reply.data.status == "OK") {
            //           alert("Bill Saved!");

            //         } 
            //         else {
            //           alert(reply.data.message);
            //         }
            //       } 
            //       else {
            //         // console.log(reply);
            //         alert(reply);
            //       }
            //     });
    }
    let loginStatus = useSelector((state) => state.isLogin);
    let nav = useNavigate();
    useEffect(() => {
        if(loginStatus == "N") {
            nav("/");
        }
        

    }, []);

    return (
        <div className="container-fluid" style={{marginTop:"100px"}}>
            <h3 className="billhead1"> SALES ORDER</h3>
            <div style={{ border: "1px solid black", padding: "0px 11px 0px 11px" }}>
                <div className="row border">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                        <div className="imagepara">
                            <div><img src={logo} /></div>
                            <div >
                                <p><b>M/S DEWAN TYRES LIMITED</b><br />
                                    BYE PASS ROAD,PARTAPUR,MEERUT<br />
                                    Udyog Aadhar No.UP56C0035272<br />
                                    GSTIN/UIN: 09AAACD6090N2Z7<br />
                                    State Name: Uttar Pradesh, Code : 09<br />
                                    CIN: L25111UP1983PLC006231<br />
                                    E-Mail : accounts@dewantyres.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 border ">
                        <table className="mb-0">
                            <tbody>
                                <tr>
                                    <td className="data">Voucher No.</td>
                                    <td>Dated</td>
                                </tr>
                                <tr>
                                    <td className="data"><b>{billData.voucherNo}</b></td>
                                    <td><b>{billData.billDate}</b></td>
                                </tr>

                                <tr>
                                    <td className="data"></td>
                                    <td>Mode/Terms of Payment</td>
                                </tr>
                                <tr>
                                    <td className="data"><b></b></td>
                                    <td><b>ADVANCE</b></td>
                                </tr>
                                <tr>
                                    <td className="data">Buyer's Ref./Order No.</td>
                                    <td>Other References</td>
                                </tr>
                                <tr>
                                    <td className="data"><b>{billData.voucherNo}</b></td>
                                    <td><b></b></td>
                                </tr>
                                <tr>
                                    <td className="data">Dispatched through</td>
                                    <td>Destination</td>
                                </tr>
                                <tr>
                                    <td ><b></b></td>
                                    <td><b></b></td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 border">
                        <div>
                            <p>Consignee(Ship to)<br />
                                <b>{billData.dealerCompany}</b><br />
                                {billData.dealerAddress}<br />
                                <label>GSTIN/UIN     :       </label>   {billData.dealerGSTIN}<br />
                                <label>State Name      :      </label>    {billData.dealerState},  <label>Code  :  </label> {billData.stateCode} <br />

                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6">
                        Terms of Delivery
                    </div>
                </div>


                <div className="row border">
                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 border">
                        <div>
                            <p>Buyer(Bill to)<br />
                                <b>{billData.dealerCompany}</b><br />
                                {billData.dealerAddress}<br />
                                <label>GSTIN/UIN     :       </label>   {billData.dealerGSTIN}<br />
                                <label>State Name      :      </label>   {billData.dealerState},  <label>Code  :  </label> {billData.stateCode} <br />

                            </p>
                        </div>
                    </div>
                </div>

                <div >


                    <div className="row border fw-bold">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">S.No.</div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">Description of Goods</div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">HSN/SAC</div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">Quantity</div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">Rate</div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">Disc. %</div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">Amount</div>
                    </div>
                    <div className="row border">
                        {billItems.map((item, index) => (
                            <>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">{index + 1}</div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4">{item.productName}</div>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">{item.hsnCode}</div>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">{item.qty} Nos.</div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{item.price}</div>
                                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                    <input className="input"
                                        onKeyPress={handleKeyPress}
                                        onPaste={handlePaste}
                                        value={discounts[index] || ""}
                                        onChange={(e) => handleDiscountChange(index, e.target.value)}
                                    /></div>
                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{item.amt}</div>
                            </>
                        ))}
                    </div>

                    <div className="row border">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{totalAmount.toFixed(2)}</div>
                    </div>
                    <div className="row border">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">Less</div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 right">Rebate & Discount on Sales</div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                            <input
                                className="input"
                                onKeyPress={handleKeyPress}
                                onPaste={handlePaste}
                                value={rebate || 0}
                                onChange={(e) => handleRebateChange(e.target.value)}
                            />
                        </div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{amountAfterRebate.toFixed(2)}</div>
                    </div>
                    <div className="row border">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 right">CGST</div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                            <input
                                className="input"
                                onKeyPress={handleKeyPress}
                                onPaste={handlePaste}
                                value={cgst || 0}
                                onChange={(e) => handleCGSTChange(e.target.value)}
                            /></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{amountOfCGST.toFixed(2)}</div>
                    </div>
                    <div className="row border">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 right">SGST</div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                            <input
                                className="input"
                                onKeyPress={handleKeyPress}
                                onPaste={handlePaste}
                                value={sgst || 0}
                                onChange={(e) => handleSGSTChange(e.target.value)
                                }
                            /></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{amountOfSGST.toFixed(2)}</div>
                    </div>
                    <div className="row border">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 right">IGST</div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">
                            <input
                                className="input"
                                onKeyPress={handleKeyPress}
                                onPaste={handlePaste}
                                value={igst || 0}
                                onChange={(e) => handleIGSTChange(e.target.value)}
                            /></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{amountOfIGST.toFixed(2)}</div>
                    </div>


                    <div className="row border">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 right">ROUND OFF.</div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">{roundOff.toFixed(2)}</div>
                    </div>

                    <div className="row border footer">
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 total">Total</div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1">{totalQuantity} Nos.</div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2"></div>
                        <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">₹ {finalAmount.toFixed(2)}</div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">Amount Chargeable(in words)</div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">E. & O.E</div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">Indian Rupees Four Lakh Two Thousand One Hundred Eighty Seven </div>

                    </div>



                </div>

                <br />
                <br />
                <div>
                    <div className="row footer">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 head2">
                            Company's PAN     :AAACD6090N
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                            Company's Bank Details<br />
                            Bank Name         :    H.D.F.C.BANK, W.K ROAD MEERUT(U.P)<br />
                            A/c No.           :    02852320000136<br />
                            Branch & IFS Code :    HDFC0000285
                        </div>

                    </div>
                </div>
                <div>
                    <div className="row  ">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 head2">

                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 last border">
                            <p>for M/S DEWAN TYRES LIMITED<br /><br /><br />
                                Authorized Signatory
                            </p>
                        </div>

                    </div>
                </div>

            </div>
            <div className="note">
                <p>This is a Computer Generated Document</p>

                <button
                    onClick={handleSubmit}
                    className="btn custom-button">Save</button>
            </div>
        </div>
    );
}