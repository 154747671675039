import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import API_AUTH from "../api_auth";
import axios from "axios";
import Pagination from "./pagination";
import FinalBill from "../Dealer/bill/finalBill";

function AdminDashboard({ commonData }) {
  let nav = useNavigate();

  let [dashBoard,setDashboard]=useState({totalCategories:0,totalProducts:0,totalItemsInStock:0,totalDealers:0,pendingOrders:[],confirmOrders:[]});
  async function getBoardData() {

    await API_AUTH().get("/DashBoard/GetAdminDashBoard").then(reply => {
      if (reply.status == 200) {
        console.log(reply.data)
          setDashboard(reply.data);  
      }      
    })
  }

  
  // Pagination Implementation
  let [orderCurrentPage, setOrderCurrentPage] = useState(1);
  let [orderPerPage, setOrderPerPage] = useState(10);

  let lastOrderIndex = orderCurrentPage * orderPerPage;
  let firstOrderIndex = lastOrderIndex - orderPerPage;
  //let currentOrders = dashBoard.pendingOrders.slice(firstOrderIndex, lastOrderIndex);

  //////


 async function createTempBill(orderId){
    await axios.post(process.env.REACT_APP_BASE_URL+"/Bills/CreateTempBill/"+orderId,{},{
      headers:{
        Authorization: `Bearer ${window.token}`
      }
    }).then(reply=>{
      if(reply.status == 200){
        if(reply.data.status == "OK"){
          nav("/finalBill", {state :{billDetails: reply.data.data}});
        }
        else{
          alert(reply.data.message);
        }

      }
      else{
        alert("Error : "+reply.status);
        
      }
    })
  }

  useEffect(() => {
    if (commonData.isLogin == "N") {
      nav("/");
    }
    else{
      getBoardData();
      
    }
  },[]);

  return (
    <>
      <div className="row container-fluid" style={{display:"grid", placeItems:"center"}}>
        <div className="col-xl-12 col-lg-10 col-md-10 col-sm-12" style={{marginTop:"100px" }}>
          <div className="row d-flex align-items-center justify-content-center ">
            {/* Card 1 */}
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2 align-items-center justify-content-center">
              <div className="card custom-card" style={{  height:"5rem", padding: "10px", backgroundColor:"#8896fd", color:"#FFFFFF" }}>
                <label>Total Categories : </label>
                <h3>{dashBoard.totalCategories}</h3>
                
              </div>
            </div>
            {/* Card 2 */}
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2 align-items-center justify-content-center">
              <div className="card custom-card" style={{  height:"5rem", padding: "10px", backgroundColor:"#f8a395",color:"#FFFFFF" }}>
                <label>Total Products : </label>
                <h4>{dashBoard.totalProducts}</h4>

              </div>
            </div>
            {/* Card 3 */}
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2 align-items-center justify-content-center">
              <div className="card custom-card" style={{ height:"5rem", padding: "10px", backgroundColor:"#c56dfb",color:"#FFFFFF" }}>
                <label>Total Items in Stocks : </label>
                <h4>{dashBoard.totalItemsInStock}</h4>
                
              </div>
            </div>
            {/* Card 4 */}
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-2 align-items-center justify-content-center">
              <div className="card custom-card" style={{  height:"5rem", padding: "10px",backgroundColor:'#c84099',color:"#FFFFFF" }}>
              <label>Dealer Registered : </label>
              <h4>{dashBoard.totalDealers}</h4>
              </div>
            </div>
          </div>
        </div>

        {/* Row 2 */}
        <div className="col-xl-12 col-lg-10 col-md-10 col-sm-12  justify-content-center mt-5 mb-5">
          <div className="row align-items-center justify-content-center ">
            {/* Card 1 */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-center mt-2 justify-content-center">
              <div className="card custom-card" style={{ maxHeight:"30rem", padding: "10px",backgroundColor:'#ffffcc' }}>
                <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                <label>Pending Orders : </label>
                <h5>{dashBoard.pendingOrders.length}</h5>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input type="search"
                          placeholder="Search order..."
                          className="form-control"
                           />
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto", height:"25rem"}}>
                <table className="table">
                  <thead>
                  <tr>
                  <th>Order ID</th>
                  <th>Dealer Code</th>
                  <th>Order Date</th>
                  <th>Amount</th>
                  <th></th>
                  </tr>
                  </thead>
                 <tbody>
                  {
                    dashBoard.pendingOrders.map((e)=>(
                      <tr>
                        <td>{e.orderId}</td>
                        <td>{e.dealerCode}</td>
                        <td>{e.orderDate.split('T')[0]}</td>
                        <td>{e.amount}</td>
                        <td><button onClick={()=> createTempBill(e.orderId)} className="btn btn-sm btn-primary">Create Bill</button></td>
                      </tr>
                    ))
                  }
                 </tbody>
                </table>
                </div>
                 <Pagination totalOrders={dashBoard.pendingOrders.length} orderPerPage={orderPerPage} setCurrentPage={setOrderCurrentPage} /> 
              </div>
            </div>

            {/* Card 2 */}
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-2 align-items-center  justify-content-center">
              <div className="card custom-card" style={{ height:"30rem", padding: "10px",backgroundColor:'#bbffcc' }}>
              <div className="row d-flex align-items-center ">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex">
                <label>Confirm Orders : </label>
                <h5>{dashBoard.confirmOrders.length}</h5>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <input type="search"
                          placeholder="Search order..."
                          className="form-control"
                           />
                          
                </div>
                </div>
                <hr/>
                <div style={{overflow:"auto"}}>
                <table className="table">
                  <thead>
                  <tr>
                  <th>Order ID</th>
                  <th>Dealer Code</th>
                  <th>Bill Date</th>
                  <th>Amount</th>
                  </tr>
                  </thead>
                  
                 <tbody>
                  {dashBoard.confirmOrders.map(e=>
                    (
                    <tr>
                      <td>{e.orderId}</td>
                      <td>{e.dealerCode}</td>
                      <td>{e.orderDate}</td>
                      <td>{e.amount}</td>
                    </tr>
                  ))}
                 </tbody>
                </table>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

let connectToStore = (state) => ({ commonData: state });
export default connect(connectToStore)(AdminDashboard);
